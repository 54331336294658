<template>
  <div class="">
    <div class="u-body">
      <div v-if="!pgLoading" class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in items"
          :key="index"
          :class="[
            item.authority ? '' : 'hidden',
            index > 4 ? 'pt-5' : '',
            item.id == 1 ? 'hidden' : '',
          ]"
        >
          <div class="card text-center">
            <div class="card-header">
              <h4>{{ item.name }}</h4>
            </div>

            <div class="card-body pt-0">
              <router-link
                :to="{ name: item.slug }"
                class="btn btn-pill btn-secondary btn-with-icon text-uppercase"
              >
                <span :class="'btn-icon mr-2 ' + item.icon"></span>
                <span>Update</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "List",
  props: ["globalSearch"],
  components: {},
  data() {
    return {
      auth: {
        role: "",
        id_token: "",
      },
      pgLoading: true,
      items: [],
      refs: "applications",
      globalSearchWatched: this.$props.globalSearch,
    };
  },
  watch: {
    globalSearch: function (new_val) {
      this.globalSearchWatched = new_val;
      this.fetchData();
    },
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    this.fetchData();
  },
  methods: {
    // fetch Data
    fetchData() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs,
        method: "GET",
        data: {},
        params: {
          status: true,
          search: this.globalSearchWatched,
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.items = res.data.rows;
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "Wow-man,",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },
  },
};
</script>
